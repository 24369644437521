import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";
import {Columns, Column} from "../components/elements/columns";
import Video from "../components/elements/video";

const HowToPage = () => (
  <Index>
    <SEO title="How-To Training Videos" />
    <Container id="product-overview-banner">
      <h1>How-To Training Videos</h1>
    </Container>
    <Container>
      <p>&nbsp;</p>
      <Columns>
        <Column><Video title="Video" videoID="HtZNk60ntKw" /></Column>
        <Column>
          <h4>8-HOUR DEFENSE Hand Sanitizer</h4>
          <p><b>How to apply hand sanitizer</b></p>
          <ol>
            <li>Place enough product in your palm to thoroughly cover your hands.<br /><small>* For best results apply to clean hands; otherwise, the mechanical barrier will form to foreign particles that may easily flake off.</small></li>
            <li>Rub hands together and allow to dry.</li>
            <li>Note: Do not rinse or wipe off the hand sanitizer before it’s thoroughly dry.</li>
            <li>Our product has been tested to be effective for greater than eight hours. However, skin cells shed prior to eight hours naturally or by abrasion. Reapply as needed.</li>
          </ol>
        </Column>
      </Columns>
      <Columns>
        <Column><Video title="Video" videoID="HtZNk60ntKw" /></Column>
        <Column>
          <h4>microSURE® All Purpose Surface Disinfectant</h4>
          <p><b>General cleaning: Spray targeted area and let dry.</b></p>
          <ol>
            <li>Disinfecting, deodorizing: Pre‐clean surface before application of product.</li>
            <li>Apply product with a hand pump trigger sprayer, low pressure coarse sprayer, or preferred applicator of choice. Hold sprayer six to eight inches from the surface to be treated.</li>
            <li>Spray area until it is adequately covered with the product. 4.No scrubbing necessary. Allow treated surfaces to air dry.</li>
          </ol>
        </Column>
      </Columns>
      <Columns>
        <Column><Video title="Video" videoID="HtZNk60ntKw" /></Column>
        <Column>
          <h4>microSURE® All Purpose Laundry Detergent</h4>
          <p><b>Instructions</b></p>
          <ol>
            <li>Load laundry into washing machine using the warmest appropriate water setting.</li>
            <li>Always use the correct dose of laundry detergent for the size of your load.
              <ul>
                <li>For medium loads, fill the cap to bar 1.</li>
                <li>For large loads, fill the cap to bar 3.</li>
                <li>For HE full loads, fill the cap to bar 5.</li>
              </ul>
            </li>
            <li>Pour detergent into the dispenser and start washer.</li>
            <li>Dry laundry as usual.</li>
          </ol>
        </Column>
      </Columns>
      <p>&nbsp;</p>
    </Container>
  </Index>
)

export default HowToPage
