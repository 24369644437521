import React from "react"

const Video = ({ videoID, title }) => {
  const fullUrl = 'https://www.youtube.com/embed/' + videoID;
  return (
    <iframe title={title} src={fullUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  )
}

export default Video
