import React from "react"

export const Columns = ({ children }) => (
  <div className="columns">
    { children }
  </div>
)

export const Column = ({ children }) => (
  <div className="column">
    { children }
  </div>
)
